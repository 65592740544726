:root{
  --primary-theme-color: #C6F8CB;
  --fibex-theme-color: #00184E;
  --white-theme-color: #fff;
  --primary-rgb-theme-color:0, 24, 78;

  /* Club Fibex */
  --club-fibex-green: #009d46;
  --club-fibex-blue: #019df4;
  --club-fibex-gray: #828282;
  --club-fibex-light-gray: #828282;
  --club-fibex-ghost-white: #f7f7f7;

  /* App Fibex */
  --app-blue: #01184e;
}
