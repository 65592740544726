/* You can add global styles to this file, and also import other style files */

@import "assets/css/color/variable.css";

@import url("https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700,800|Raleway:300,400,500,600,700,800,900");

/* Bootstrap import */
@import "~bootstrap/dist/css/bootstrap.min.css";

/* Font-awesome import */
@import "~@fortawesome/fontawesome-free/css/all.min.css";

/* Ionic icons import */
@import "assets/css/ionicons.min.css";

/* Animate css import */
@import "~animate.css/animate.min.css";

/*********************************************************
  Uncomment this Code to enable latest version of Ionic icons
  !!!! Warning : Old version of ionic font might not work !!!!

  @import "~ionicons/dist/css/ionicons.min.css";
*********************************************************/

/* Custom css file import */
@import "assets/css/custom.css";

/* Customize Style css*/
@import "assets/css/style-customizer.css";

/*Owl Carousel*/
@import "~owl.carousel/dist/assets/owl.carousel.css";
@import "~owl.carousel/dist/assets/owl.theme.default.css";

@import "assets/css/magnific-popup.css";

@import url("../node_modules/bootstrap/dist/css/bootstrap.min.css");


/* Fonts */
@font-face {
  font-family: 'Poppins-Bold';
  src: url('./assets/fonts/Poppins/Poppins-Bold.ttf');
}

@font-face {
  font-family: 'Poppins-Light';
  src: url('./assets/fonts/Poppins/Poppins-Light.ttf');
}

@font-face {
  font-family: 'Poppins-Medium';
  src: url('./assets/fonts/Poppins/Poppins-Medium.ttf');
}


/* ANIMATIONS  */

.animated {
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

@-webkit-keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.fa,
.fas {
  font-weight: 900 !important;
}

button.disabled {
  cursor: not-allowed;
}

header#main-header .navbar .menu li .sub-menu li,
.navbar .menu li .sub-menu li {
  background: #fff;
  border-bottom: 1px solid #eee;
  color: #23292c;
  font-size: 14px;
  margin: 0;
  display: inline-block;
  width: 100%;
}

header#main-header .navbar .menu li,
.navbar .menu li {
  margin: 0 30px 0 0;
  position: relative;
}

button:focus {
  outline: none !important;
}

.header-style2 {
  padding: 0px 15px !important;
}

.header-style2 > .nav-item.menu-item {
  padding: 10px 0px !important;
}

.border-radius-4 {
  border-radius: 4px !important;
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

.backdropBackground {
  background-color: #bdbdbd;
}

#dialog_title {
  color: white;
}

#btn_send {
  background-color: white;
}

.modal-content {
  background-color: #ffffff;
  font-family: sans-serif;
  color: #ffffff;
}

.modal-header {
  border: none;
  font-family: sans-serif;
}

.modal-footer {
  border: none;
  font-family: sans-serif;
}

#modal-basic-title {
  /* color: #ffffff; */
  font-weight: bold;
}

.close-modal-button {
  position: absolute;
  right: 0;
  top: 0;
  margin-right: 1rem;
  margin-top: 1rem;
}

#send:disabled {
  background-color: rgb(109, 108, 108);
  color: #ffffff;
  font-weight: bold;
}

#send {
  background-color: #c6f8cb;
  color: #00184e;
  width: 100%;
  font-weight: bold;
}

#contact_form label {
  color: #ffffff;
  font-family: sans-serif;
  font-size: 18px;
  font-weight: bold;
}

.btn-close {
  width: 8%;
  background-color: #ffffff;
  font-family: "Roboto", sans-serif;
  text-align: center;
  color: #00184e;
  border-radius: 5px;
  border: 2px solid #15da92;
}

#btn_close:hover {
  cursor: pointer;
}

#contact_form div input {
  border-color: #c6f8cb;
}

#phone::-webkit-inner-spin-button,
#phone::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

#getInTouch {
  background-color: #00184e;
  font-family: sans-serif;
  color: #ffffff;
}

/* #formContact div input{
  border-color: #c6f8cb;
} */

#formContact label {
  color: #ffffff;
  font-family: sans-serif;
  font-size: 18px;
  font-weight: bold;
}

#enviar:disabled {
  background-color: rgb(109, 108, 108);
  color: #ffffff;
  font-weight: bold;
}

#enviar {
  background-color: #15da92;
  color: #00184e;
  width: 100%;
  font-weight: bold;
}

#getInTouch h4 {
  color: #ffffff;
  font-weight: bold;
}

.formContact {
  padding: 7px;
  position: relative;
  top: -24px;
  left: 0px;
  width: 200px;
  height: 24px;
  line-height: 10px;
}

.closebtn:hover {
  filter: brightness(120%);
  cursor: pointer;
}

/* ----------------------------- Style seccion Empresa ------------------------------- */

.iq-img i {
  background: #3277f8;
}
.iq-img {
  border-radius: 100%;
  position: relative;
  border: 20px solid #f3f7fd;
  background: #e7effa;
  text-align: center;
  height: 200px;
  width: 200px;
  margin: 0 auto 30px;
  line-height: 160px;
}
.fancy-service-list .fancy-service-detail .iq-img i {
  color: #ffffff;
  position: absolute;
  height: 37px;
  width: 37px;
  border-radius: 90px;
  right: 0;
  top: -20px;
  font-size: 16px;
  line-height: 37px;
  background: linear-gradient(
    to right,
    var(--linear-color-1) 0%,
    var(--linear-color-2) 51%,
    var(--linear-color-3) 100%
  );
}
.fancy-service-list .fancy-service-detail .iq-img img {
  width: auto;
  margin: 0 auto;
}
.fancy-service-list .fancy-service-detail {
  margin: 15px 0;
}
.fancy-service-list {
  box-shadow: 0px 20px 90px 0px rgba(0, 0, 0, 0.15);
  padding: 45px 30px;
}

.bg-box {
  background-color: #f1f1fb;
}
.home-six-bg {
  background-image: url(./assets/images/Fibex/614.png);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.sofbox-feature-circle .dot-circle {
  border-radius: 100%;
  height: 950px;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;
  border: 1px solid #fff;
  opacity: 0.3;
}
@media (max-width: 767px) {
  .maintenance h2 {
    font-size: 40px;
  }
  .iq-coming h1 {
    font-size: 64px;
  }
  .iq-coming .countdown li {
    width: 63px;
    height: 120px;
    margin: 0 10px;
  }
  .sofbox-feature-circle ul {
    text-align: center;
  }
  .sofbox-feature-circle .sofbox-img ul li .feature-info {
    height: 126px !important;
    width: 126px !important;
  }
  .sofbox-feature-circle .sofbox-img ul li .feature-info h6 {
    font-size: 14px;
  }
  .sofbox-feature-circle .sofbox-img ul li {
    height: 115px;
    width: 115px;
  }
  .features .feature-info {
    float: left;
  }
}
.sofbox-feature-circle .sofbox-img ul li .feature-info {
  text-align: center;
  height: 150px;
  border-radius: 100%;
  background: #ffffff;
  width: 150px;
  padding: 20px;
}
.sofbox-feature-circle .sofbox-img ul li {
  position: absolute;
}
.sofbox-feature-circle .sofbox-img ul li:nth-child(1) {
  left: 3%;
  top: 50%;
}
.sofbox-feature-circle .sofbox-img ul li:nth-child(2) {
  left: 20%;
  top: 15%;
}
.sofbox-feature-circle .sofbox-img ul li:nth-child(3) {
  right: 20%;
  top: 15%;
}
.sofbox-feature-circle .sofbox-img ul li:nth-child(4) {
  right: 3%;
  top: 58%;
}
.sofbox-feature-circle .sofbox-img ul li:nth-child(5) {
  left: 20%;
  bottom: 15%;
}
.sofbox-feature-circle .sofbox-img ul li:nth-child(6) {
  left: 24%;
  top: 40%;
}
.sofbox-feature-circle .sofbox-img ul li:nth-child(7) {
  right: 40%;
  top: 25%;
}
.sofbox-feature-circle .sofbox-img ul li:nth-child(8) {
  right: 20%;
  top: 45%;
}
.sofbox-feature-circle .sofbox-img ul li:nth-child(9) {
  right: 20%;
  bottom: 10%;
}
.sofbox-feature-circle .sofbox-img ul li:nth-child(1) .feature-info,
.sofbox-feature-circle .sofbox-img ul li:nth-child(2) .feature-info,
.sofbox-feature-circle .sofbox-img ul li:nth-child(3) .feature-info,
.sofbox-feature-circle .sofbox-img ul li:nth-child(4) .feature-info {
  height: 142px;
  width: 142ng servepx;
}
.sofbox-feature-circle .sofbox-img ul li .feature-info .feature-img {
  margin-bottom: 10px;
  display: inline-block;
  vertical-align: middle;
  line-height: initial;
}

.testimonial-circle .main-circle {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  margin-right: -50%;
}
.about-style .main-circle {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
}
.sofbox-feature-circle .main-circle {
  margin: 0 auto;
  text-align: center;
}
.sofbox-feature-circle .main-circle img.fancy-list-img {
  margin-top: 40%;
}

.about-style .circle-bg {
  width: 80px;
  text-align: center;
  position: relative;
  height: 80px;
  border-radius: 900px;
}
.about-style .circle-bg i {
  font-size: 40px;
  line-height: 80px;
}
.about-style .circle-bg img {
  width: 50px;
  margin-top: 15px;
}
.testimonial-circle .circle-bg {
  width: 140px;
  text-align: center;
  position: relative;
  height: 140px;
  line-height: 140px;
  border-radius: 900px;
  background-image: -moz-linear-gradient(
    0deg,
    var(--linear-color-1) 0%,
    var(--linear-color-2) 100%
  );
  background-image: -webkit-linear-gradient(
    0deg,
    var(--linear-color-1) 0%,
    var(--linear-color-2) 100%
  );
  box-shadow: 0px 10px 20px 0px rgba(139, 118, 246, 0.4);
}

.fancy-list-box .fancy-list-img {
  margin-right: 30px;
  width: 80px;
  height: 80px;
  line-height: 80px;
  color: #ffffff;
  position: relative;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  font-size: 30px;
}
.fancy-list-box .service-detail {
  flex: 1;
  display: inline-block;
  vertical-align: middle;
}
.fancy-list-box .fancy-list-img:before {
  border-radius: 90px;
  line-height: 80px;
  width: 80px;
  height: 80px;
  text-align: center;
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
  opacity: 0.8;
  transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  -moz-transition: all 0.5s ease-in-out;
  -ms-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  -webkit-transition: all 0.5s ease-in-out;
  border-top-left-radius: 0px;
}
.fancy-list-box .fancy-list-img.purple-hover:before {
  background-image: -moz-linear-gradient(
    90deg,
    rgb(135, 154, 251) 0%,
    rgb(23, 51, 195) 100%
  );
  background-image: -webkit-linear-gradient(
    90deg,
    rgb(135, 154, 251) 0%,
    rgb(23, 51, 195) 100%
  );
}
.fancy-list-box .fancy-list-img.org-hover:before {
  background-image: -moz-linear-gradient(
    90deg,
    rgb(230, 166, 160) 0%,
    rgb(255, 106, 92) 100%
  );
  background-image: -webkit-linear-gradient(
    90deg,
    rgb(230, 166, 160) 0%,
    rgb(255, 106, 92) 100%
  );
}
.fancy-list-box .fancy-list-img.green-hover:before {
  background-image: -moz-linear-gradient(
    90deg,
    rgb(149, 239, 218) 0%,
    rgb(100, 216, 190) 100%
  );
  background-image: -webkit-linear-gradient(
    90deg,
    rgb(149, 239, 218) 0%,
    rgb(100, 216, 190) 100%
  );
}
.fancy-list-box {
  display: flex;
}
.iq-accordion.career-style .active-faq .row {
  background: #eff1fe;
  padding: 10px;
  border-radius: 4px;
}
.test-shadow .testimonial-style.owl-carousel .iq-testimonial {
  margin: 15px 36px 66px 36px;
}
.test-shadow .testimonial-style.owl-carousel .owl-dots {
  position: absolute;
  top: 68%;
  left: -61%;
  width: auto;
}

.bg-img-jump.left,
.bg-img-jump.right {
  position: relative;
}
.bg-img-jump img {
  position: absolute;
  border-radius: 90px;
  width: 60px;
}
.bg-img-jump.left .img-two {
  position: absolute;
  left: -26%;
  top: -26%;
}
.bg-img-jump.left .img-one {
  position: absolute;
  left: 18%;
  top: -100px;
}
.bg-img-jump.left .img-three {
  position: absolute;
  left: -15%;
  top: 132px;
}
.bg-img-jump.left .img-four {
  position: absolute;
  top: 200px;
  left: -32%;
}
.bg-img-jump.right .img-two {
  position: absolute;
  right: -26%;
  top: -26%;
}
.bg-img-jump.right .img-one {
  position: absolute;
  right: 18%;
  top: -100px;
}
.bg-img-jump.right .img-three {
  position: absolute;
  right: -15%;
  top: 132px;
}
.bg-img-jump.right .img-four {
  position: absolute;
  top: 200px;
  right: -32%;
}

.testimonial-style.owl-carousel .testimonial-info:before {
  content: "";
  display: inline-block;
  position: absolute;
  font-size: 84px;
  width: 205px;
  background: url(../src/assets/images/Fibex/qu.png) no-repeat;
  height: 140px;
  opacity: 1;
  top: 111%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  z-index: 1;
}
.testimonial-style.owl-carousel .owl-item .testimonial-info img {
  width: 65px;
  height: 65px;
  display: inline-block;
  margin-bottom: 45px;
  box-shadow: 0px 8px 38px 0px rgba(0, 0, 0, 0.1);
  border: 2px solid #ffffff;
}
.testimonial-style.owl-carousel .testimonial-name {
  text-align: center;
  position: relative;
  z-index: 1;
  display: block;
}
.testimonial-style.owl-carousel .testimonial-name .content {
  display: inline-block;
}
.testimonial-style.owl-carousel .iq-testimonial p {
  margin-bottom: 0;
  z-index: 1;
  position: relative;
}
.testimonial-style.owl-carousel .owl-dots {
  position: absolute;
  bottom: 0px;
}
.testimonial-style.owl-carousel .iq-testimonial {
  padding: 45px 30px;
  margin: 52px 30px 70px 30px;
  box-shadow: -6px 15px 30px 0px rgba(0, 0, 0, 0.08);
  background: #ffffff;
  border-radius: 4px;
  display: block;
  position: relative;
}
.testimonial-style.owl-carousel .iq-testimonial:before {
  content: "";
  position: absolute;
  bottom: -12px;
  width: 90%;
  z-index: -1;
  border-radius: 4px;
  height: 100%;
  background: #fff;
  box-shadow: -6px 15px 30px 0px rgba(0, 0, 0, 0.08);
}
.testimonial-style.owl-carousel .iq-testimonial:after {
  content: "";
  position: absolute;
  bottom: -24px;
  width: 82%;
  left: 0;
  margin: 0 auto;
  right: 0;
  z-index: -2;
  border-radius: 4px;
  height: 100%;
  background: #fff;
  box-shadow: -6px 15px 30px 0px rgba(0, 0, 0, 0.08);
}
.testimonial-style .testimonial-info {
  position: relative;
  text-align: center;
  display: block;
  margin-bottom: 25px;
}
.test-shadow .testimonial-style.owl-carousel .iq-testimonial {
  margin: 15px 36px 66px 36px;
}

.owl-carousel .owl-dots {
  position: relative;
  width: 100%;
  display: inline-block;
  text-indent: inherit;
  text-align: center;
  cursor: pointer;
}

.pointer {
  cursor: pointer !important;
}

.mapOptions {
  z-index: 1;
  position: absolute;
  bottom: 10%;
  left: 4%;
  display: flex;
  justify-content: space-between;
  width: 300px;
  height: 50px;
}

.mapOptions > select {
  width: 45%;
}

.mapOptions > select > option {
  text-align: left;
}

.mapForm {
  background: url("./assets/images/Fibex/imgFooter.png");
  background-image: no-repeat;
  background-size: cover;
}

.mapFormBody {
  position: absolute;
  width: 28%;
  top: 2%;
  left: 64%;
}

.popup-images {
  position: relative;
}
.popup-images.align-left .img-one img {
  position: relative;
  left: -1%;
  max-width: none !important;
}
.popup-images.align-left .img-two {
  left: 306px;
  top: 0px;
}
.popup-images.align-left .img-three {
  left: -158px;
  top: 195px;
}

.iq-objects.style-two.right-top .iq-objects-02 img {
  position: absolute;
  right: -300px;
  top: 0;
  z-index: -2;
  -webkit-animation: bounces 2s infinite alternate;
}
img {
  max-width: 176%;
  height: auto;
}

.title-box h2 {
  margin-bottom: 15px;
}

@media (max-width: 767px) {
  footer.footer-two .footer-input {
    display: block;
    text-align: center;
  }
  footer.footer-two .footer-input .button.sign-btn {
    width: inherit;
    display: inline-block;
    margin-top: 30px;
  }
  section {
    padding: 80px 0;
  }
  header ul.shop_list {
    display: none;
  }
  .shop-Product .fancy-icon {
    border: none;
  }
  .title-box h2 {
    font-size: 24px;
  }
  .advance-feature .service-price ul li {
    display: inline-block;
    width: 100%;
  }
  .popup-absolute-images .img-three {
    bottom: -153px;
  }
  .contact-block .vc_column-inner,
  .widget-area {
    margin-top: 30px;
  }
  .commentlist .iq-comment-wrap {
    display: block;
  }
  .commentlist ol.children {
    padding-left: 30px;
  }
  .iq-masonry.iq-columns-3 .iq-masonry-item {
    padding-right: 0;
    padding-left: 0;
  }
  .wpb-js-composer .vc_tta.vc_general .vc_tta-panel-body {
    padding: 30px 15px !important;
  }
  .wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-classic
    .vc_tta-panel.vc_active
    .vc_tta-panel-title
    > a {
    color: #437eeb !important;
  }
  .wpb-js-composer
    .vc_tta-color-grey.vc_tta-style-classic
    .vc_tta-panel
    .vc_tta-panel-title
    > a {
    color: #313e5b !important;
  }
  .vc_chart .vc_chart-legend li {
    margin-right: 15px !important;
  }
  .vc_line-chart .vc_chart-legend {
    width: 60% !important;
  }
  .admin-bar header.style-one {
    top: 0;
  }
  .slider-container .slider-content {
    width: 40%;
    height: 40%;
  }
  .slider-container .slider-left {
    right: 74%;
  }
  .slider-container .slider-right {
    left: 74%;
  }
  .screenshots-slider {
    min-height: 250px;
  }
  .saas-works-box .media {
    display: inline-block;
  }
  .saas-works-box .media img,
  .saas-works-box .media i,
  .analysis-box .row-eq-height .col-sm-2 {
    margin-bottom: 30px;
  }
  .popup-images.align-right .img-two {
    max-width: 35%;
  }
  .iq-accordion.career-style.faq-style .active-faq a.accordion-title {
    padding-left: 30px;
  }
  .iq-accordion.career-style.faq-style
    .iq-accordion-block
    .accordion-details
    p {
    padding: 0 15px;
  }
  .popup-images.align-right .img-three {
    left: 18%;
    width: 82%;
    top: 34px;
  }
  .iq-accordion.career-style.faq-style
    .iq-accordion-block
    .accordion-title
    span {
    margin-top: 15px;
    display: inline-block;
  }
  .popup-images.align-right .img-two {
    top: inherit;
    right: inherit;
    left: 0;
    bottom: 0;
  }
  .advance-feature .col-lg-6.border-right-0 {
    padding: 20px;
  }
  .bg-img-platform img {
    width: 60px;
  }
  .mapFormBody {
    position: relative;
    width: 86%;
    top: 2%;
    left: 7%;
  }
  /* .mapOptions{
      z-index: 1;position: absolute;top: 48%;right:calc(100% - 216px);
    } */
  .Fancy-box-position .fancy-style-five {
    right: 1%;
  }
  .Fancy-box-position .fancy-style-five {
    position: relative;
    width: 100%;
  }

  .custom-tab.horizontaltab-slider ul li a {
    margin-right: 5px;
  }
  .custom-tab.horizontaltab-slider ul li {
    margin-bottom: 30px;
  }
  .custom-tab.horizontaltab-slider ul li:last-child {
    margin-bottom: 0px;
  }
  .custom-tab.horizontaltab-slider .tab-pane.active {
    padding: 20px 77px 50px;
  }

  ul.arvr-headset {
    padding-right: 0px;
  }
}
section {
  padding: 120px 0;
}

.sofbox-feature-circle .dot-circle {
  border-radius: 100%;
  height: 950px;
  width: 950px;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  top: 10%;
  left: 0;
  right: 0;
  border: 1px solid #fff;
  opacity: 0.3;
}

.sofbox-feature-circle .effect-circle {
  border-radius: 100%;
  height: 600px;
  width: 600px;
  margin: 0 auto;
  text-align: center;
  position: absolute;
  top: 20%;
  left: 0;
  right: 0;
  border: 1px solid #fff;
  opacity: 0.3;
}

.sofbox-feature-circle .sofbox-img ul li:nth-child(1) .feature-info,
.sofbox-feature-circle .sofbox-img ul li:nth-child(2) .feature-info,
.sofbox-feature-circle .sofbox-img ul li:nth-child(3) .feature-info,
.sofbox-feature-circle .sofbox-img ul li:nth-child(4) .feature-info {
  height: 135px;
  width: 135px;
}

section {
  padding: 0px 0;
}

element.style {
  visibility: visible;
  animation-name: zoomIn;
}
.sofbox-feature-circle {
  position: relative;
  overflow: hidden;
}

.col,
.col-1,
.col-10,
.col-11,
.col-12,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-auto,
.col-lg,
.col-lg-1,
.col-lg-10,
.col-lg-11,
.col-lg-12,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-auto,
.col-md,
.col-md-1,
.col-md-10,
.col-md-11,
.col-md-12,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-auto,
.col-sm,
.col-sm-1,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-auto,
.col-xl,
.col-xl-1,
.col-xl-10,
.col-xl-11,
.col-xl-12,
.col-xl-2,
.col-xl-3,
.col-xl-4,
.col-xl-5,
.col-xl-6,
.col-xl-7,
.col-xl-8,
.col-xl-9,
.col-xl-auto {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.more {
  bottom: 18%;
  position: absolute;
  left: 32%;
  background-color: #0f2859;
  color: #ffffff;
  border-radius: 5px;

  @media screen and (max-width: 206px) {
    bottom: 12%;
    position: absolute;
  }
}

.more:hover {
  background-color: #14db94;
}

.bghBlue {
  background-color: #397aa7;
  color: #000000;
}

.bgBlue {
  background-color: #7cc8ed;
  color: #000000;
}

div.bgBlue h6,
div.bghBlue h6 {
  color: #ffffff;
}

.bgBlack {
  color: #000000;
}

/* #arr{
  margin-left: 82%;
  cursor: pointer;
} */

.textcontent {
  color: #305c80;
  font-family: "Trebuchet MS", "Lucida Sans Unicode", "Lucida Grande",
    "Lucida Sans", Arial, sans-serif;
}

.textcontent2 {
  color: #ffffff;
}

#btnpdf {
  background-color: #000000;
  color: #ffffff;
  font-family: sans-serif;
  font-style: normal !important;
  border-radius: 5px;
  font-size: 24px;
  height: 64px;
}

.btngoto {
  border-radius: 5px;
  background: linear-gradient(to right, #e42a88, #ffd085);
}

.container_drowdown {
  background-color: #14db94;
  font-size: 1.3rem;
  border-radius: 0.3em;
  padding: 1em 1.5em 1em 1em;
}

.form-img {
  margin-left: auto;
  margin-right: auto;
  width: 80%;
  padding: 0;
}

.form-img2 {
  margin-left: auto;
  margin-right: auto;
  width: 60%;
  background-repeat: repeat;
}

.form-subtitle {
  text-align: center;
  color: #ffffff;
  font-weight: bold;
}

.anexus h3,
h4 {
  text-align: center;
  color: #305c80;
  font-family: sans-serif;
}

.anexus-b {
  text-align: center;
  color: #000000;
  font-family: sans-serif;
}

.bgl {
  background: linear-gradient(to top right, #ffffff, #7cc8ed, #ffffff);
}
.bgr {
  background: linear-gradient(to bottom right, #ffffff, #7cc8ed, #ffffff);
}

.swal-wide {
  width: 850px !important;
}

.modalngb {
  height: 60px;
}

.modalclass {
  margin-top: 12%;
  max-height: 60%;
}

.bgt {
  background: linear-gradient(to bottom, #f57f83, #ffd085);
}

.bgb {
  background: linear-gradient(to top, #f57f83, #ffd085);
}

.bg_form{
  background: url("./assets/images/Fibex/imgFooter.png");
  background-image: no-repeat;
  background-size: cover;
}

/* ::-webkit-scrollbar-track
{
	border: 5px solid white;
	background-color: #b2bec3;
}

::-webkit-scrollbar
{
	width: 15px;
	background-color: #dfe6e9;
}

::-webkit-scrollbar-thumb
{
	background-color: rgb(24,46,95);
	border-radius: 10px;
} */


::-webkit-scrollbar-track
{
	/* -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); */
	background-color: white;
	border-radius: 10px;
}

::-webkit-scrollbar
{
	width: 12px;
	background-color: #F5F5F5;
}

::-webkit-scrollbar-thumb
{
	border-radius: 10px;
	background-image: -webkit-gradient(linear,
									   left bottom,
									   left top,
									   color-stop(0.44, rgb(122,153,217)),
									   color-stop(0.72, rgb(73,125,189)),
									   color-stop(0.86, rgb(28,58,148)));
}

.rounded-modal .modal-content {
  border-radius: 30px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.4) 0px 30px 90px;
  -webkit-animation-duration: 1s;
  animation-duration: 1s;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-animation-name: fadeInUp;
  animation-name: fadeInUp;
}

.bg_image {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media only screen and (max-width: 1300px) {
  header#main-header .container-fluid {
    padding: 0 15px;
  }
}

/*------------------------------------------------------------------------------
                            Fonts Poppins
--------------------------------------------------------------------------------*/
.font_poppins_light {
  font-family: 'Poppins-Light' !important;
}
.font_poppins_medium {
  font-family: 'Poppins-Medium' !important;
}
.font_poppins_bold {
  font-family: 'Poppins-Bold' !important;
}

/*------------------------------------------------------------------------------
                            Club Fibex Colors
--------------------------------------------------------------------------------*/
.color_club_green {
  color: var(--club-fibex-green) !important;
}
.color_club_gray {
  color: var(--club-fibex-gray) !important;
}
.color_club_light_gray {
  color: var(--club-fibex-light-gray) !important;
}
.color_club_blue {
  color: var(--club-fibex-blue) !important;
}


.font_description {
  font-size: 28px;
}
@media only screen and (max-width: 555px) {
  .font_description {
    font-size: 19px;
  }
}

.bg_app_color {
  background: var(--app-blue) !important;
}

.isClubFibexFooter{
  background-color: white !important;
  color: #b9b9b9 !important;
}

.fondo {
  background: url('/assets/images/Fibex/imgFooter.png') 0 0/100% 100% no-repeat !important;
}

.white{
  color: white !important;
}
